/**
 * BxSlider v4.0 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2012
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0;
  *zoom: 1;
}

.bx-wrapper img {
  width: 100%;
  max-width: 100%;
  display: block;
}
