/*

Sprint Template

http://www.templatemo.com/tm-401-sprint

*/

/* ==========================================================================
    HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
   ========================================================================== */
html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.main-menu ul, .site-slider ul, ul.social {
  padding: 0;
  margin: 0;
  list-style: none;
}

.item-small, .item-large, .product-item .item-thumb {
  overflow: hidden;
}
.item-small img, .item-large img, .product-item .item-thumb img {
  width: 100%;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */
.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  *text-indent: -9999px;
}

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

/* ==========================================================================
    Forms
   ========================================================================== */
form input[type="text"]:focus,
form input[type="email"]:focus,
form input[type="number"]:focus,
form input[type="password"]:focus {
  outline: none !important;
}

/* ==========================================================================
   Print styles
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /*
   * Don't show links for images, or javascript/internal links
   */
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
    /* h5bp.com/t */
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
/* ==========================================================================
    Structure & Defaults
   ========================================================================== */
html, body {
  font-size: 100%;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: baseline;
}

body {
  font: 13px/21px "Open Sans", Arial, sans-serif;
  color: #838383;
  background-color: white;
}

a {
  color: #26b864;
  text-decoration: none !important;
  -webkit-transition: all 200ms ease-in;
  -moz-transition: all 200ms ease-in;
  -ms-transition: all 200ms ease-in;
  -o-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}
a:hover {
  color: #f5a200;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", Arial, sans-serif;
  color: #444444;
}

h1 {
  font-size: 2.6em;
}

h2 {
  font-size: 2.2em;
}

h3 {
  font-size: 1.8em;
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: .8em;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.section-title1 {
  padding-bottom: 50px;
  text-transform: uppercase;
  font-weight: 600;
}
.section-title1:before {
  content: '';
  width: 180px;
  height: 3px;
  background: url(../../../../images/hevea/t-left.png) no-repeat center;
  margin-bottom: 10px;
  margin-right: 15px;
  display: inline-block;
}
.section-title1:after {
  content: '';
  width: 180px;
  height: 3px;
  background: url(../../../../images/hevea/t-right.png) no-repeat center;
  margin-bottom: 10px;
  margin-left: 15px;
  display: inline-block;
}

.section-title {
  margin-top: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
  text-transform: uppercase;
  font-weight: 600;
}
.section-title:before {
  content: '';
  width: 180px;
  height: 3px;
  background: url(../../../../images/hevea/t-left.png) no-repeat center;
  margin-bottom: 10px;
  margin-right: 15px;
  display: inline-block;
}
.section-title:after {
  content: '';
  width: 180px;
  height: 3px;
  background: url(../../../../images/hevea/t-right.png) no-repeat center;
  margin-bottom: 10px;
  margin-left: 15px;
  display: inline-block;
}

.bigger-text {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 50px;
}

/* ==========================================================================
    Header Site Styling
   ========================================================================== */
.site-header {
  position: fixed;
  min-height: 80px;
  height: 80px;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  box-shadow:0 0 5px rgba(0,0,0,0.15);
}

.img{
  width:100%;
}

#templatemo_logo {
  display: block;
}
#templatemo_logo h1 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 102px;
  display: block;
}
#templatemo_logo h1 a {
  margin-left: 140px;
  color: white;
  display: block;
}

.main-menu {
  text-align: right;
  position: relative;
}
.main-menu ul {
  position: absolute;
  background-color: #fff;
  width: 100%;
}
.main-menu ul li {
  display: inline-block;
  width: 110px;
  text-align: center;
  line-height: 80px;
}
.main-menu ul li a {
  color: green;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  outline: 0;
  border: 0;
  display: block;
}
.main-menu ul li a.current {
  background: url(../../../images/hevea/active-state.png) no-repeat;
  background-position: center 55px;
}

.toggle-menu {
  display: none;
  margin: 40px 35px 0 0;
  font-size: 24px;
  color: #444444;
  text-align: right;
}

.responsive .main-menu {
  display: none;
}
.responsive .main-menu ul li {
  width: 100%;
}
.responsive .main-menu ul li a {
  line-height: 55px;
  padding: 10px 20px;
}
.responsive .main-menu ul li a.current {
  background-image: none;
  color: #26b864;
  border-bottom: 0;
}

/* ==========================================================================
    Slider Styling
   ========================================================================== */
.site-slider {
  padding-top: 102px;
  box-shadow:0px 2px 5px rgba(0,0,0,0.15);
}
.site-slider ul li {
  float: left;
  margin: 0;
}

.bx-wrapper img {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.bx-wrapper {
  background-color: #161616;
}

.bx-thumbnail-wrapper {
  background-color: #161616;
  text-align: center;
  margin-top: -1px;
  position: relative;
}

.caption-wrapper {
  position: relative;
}

.slider-caption {
  position: absolute;
  z-index: 988;
  bottom: 140px;
  background-color: white;
  display: inline-block;
  right: 0;
}
.slider-caption h2 {
  font-size: 30px;
  margin: 20px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
}

#bx-pager a {
  margin: 20px 10px 0 20px;
  padding: 0 0 40px 0;
  display: inline-block;
  opacity: 0.4;
  filter: alpha(opacity=40);
  outline: 0;
  border: 0;
}
#bx-pager a img {
  outline: 0;
  border: 0;
}
#bx-pager a.active {
  opacity: 1;
  filter: alpha(opacity=100);
  background: url(../../../../images/hevea/active-state.png) no-repeat;
  background-position: center 85px;
}

.bx-controls {
  position: relative;
}

.bx-controls-direction {
  position: absolute;
  text-align: right;
  bottom: 80px;
  right: -2px;
  z-index: 998;
}
.bx-controls-direction a {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.9);
  display: block;
  float: left;
  margin-right: 2px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  color: white;
}

/* ==========================================================================
    Services Styling
   ========================================================================== */
#services {
  background-color: #fff;
  padding-top: 160px;
  margin-top: -60px;
}

.service-item {
  height: 250px;
  padding-bottom: 40px;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.service-item h3 {
  font-size: 18px;
  font-weight: 600;
}
.service-item .service-icon {
  background-image: url(../../../../images/hevea/servicos.png);
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 113px;
  display: block;
}
.service-item .service-icon.prod{
  background-position: 0px 0px;
}
.service-item .service-icon.clones{
  background-position: -104px 0px;
}
.service-item .service-icon.est{
  background-position: -208px 0px;
}
.service-item .service-icon.comp{
  background-position: -312px 0px;
}
.service-item .service-icon.not{
  background-position: -416px 0px;
}
.service-item .service-icon.cot{
  background-position: -520px 0px;
}
.service-item .service-icon.relat{
  background-position: -625px 0px;
}
.service-item .service-icon.sang{
  background-position: -728px 0px;
}
.service-item .service-icon.maq{
  background-position: -832px 0px;
}
.service-item .service-icon.ins{
  background-position: -935px 0px;
}
.service-item .service-icon.fin{
  background-position: -1040px 0px;
}
.service-item .service-icon.serv{
  background-position: -1145px 0px;
}

/* ==========================================================================
    Promotion Styling
   ========================================================================== */
.item-small {
  padding: 20px;
  background-color: #26b864;
  color: white;
  text-align: center;
}
.item-small h4 {
  color: white;
  line-height: 24px;
  margin: 20px 0 0 0;
}

.item-large .item-header {
  margin: 30px 0;
}
.item-large .item-header h2 {
  margin: 0;
}
.item-large .item-header span i {
  display: inline-block;
  font-size: 18px;
  color: #e7d323;
  margin-left: 3px;
}
.item-large .item-header span i:first-child {
  margin-left: 10px;
}

/* ==========================================================================
    Contact Styling
   ========================================================================== */
#map {
  width: 100%;
  height: 380px;
  outline: 0;
  border: 0;
}

#mapa {
  width: 100%;
  height: 380px;
  outline: 0;
  border: 0;
  box-shadow:0 1px 5px rgba(0,0,0,0.15)
}

input, textarea {
  margin-bottom: 30px;
  background-color: #f6f7f8;
  padding: 14px;
  font-size: 14px;
  color: #838383;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #b5c1c9;
  width: 100%;
}

.contact-form textarea {
  height: 130px;
}

input.button {
  display: inline-block;
  width: auto;
  background: url(../../../../images/hevea/active-state.png) no-repeat;
  background-position: center bottom;
  color: #26b864;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 27px;
  border: 0;
}

.button {
  display: inline-block;
  width: auto;
  background: url(../../../../images/hevea/active-state.png) no-repeat;
  background-position: center bottom;
  color: #26b864;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 27px;
  border: 0;
}

.site-footer {
  border-top: 1px solid #e7e7e7;
  background-color: #26b864;
  color: white;
  padding: 25px 0;
}
.site-footer a {
  color: white;
}
.site-footer span {
  margin-top: 12px;
  display: block;
}

ul.social {
  text-align: right;
}
ul.social li {
  display: inline-block;
}
ul.social li a {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-block;
}

/*------------------------------------------------------
	RESPONSIVE STYLES 
--------------------------------------------------------*/
@media (max-width: 991px) {
  .main-menu, .bx-thumbnail-wrapper {
    display: none;
  }

  .toggle-menu {
    display: block;
  }

  .site-header .container {
    padding: 0;
  }

  .slider-caption {
    bottom: 80px;
  }

  .bx-controls-direction {
    bottom: 30px;
  }

  .section-title:after {
    display: none;
  }
  .section-title:before {
    display: none;
  }

  .main-menu {
    display: none;
    border-top: 1px solid #2f2f2f;
    text-align: left;
  }
  .main-menu ul li {
    padding: 0;
    margin: 0;
    display: block;
    border-bottom: 1px solid #2f2f2f;
  }
  .main-menu ul li:last-child {
    border-bottom: 0;
  }
  .main-menu ul li a {
    padding: 20px;
    color: green;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    display: block;
  }
}
@media (max-width: 767px) {
  .section-title {
    font-size: 24px;
  }

  .contact-form {
    margin-top: 60px;
  }

  .item-large {
    margin: 40px 0;
  }

  .slider-caption {
    display: none;
  }

  #templatemo_logo h1 a {
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

.portfolio-carousel {
  box-shadow:0px 2px 5px rgba(0,0,0,0.15);
  padding: 0;
  color: white;
  background-color: #222222;
}
.portfolio-carousel .item {
  background-color: #222222;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  padding: 50px 55px;
  text-align: center;
}
.portfolio-carousel .item .portfolio-image {
  margin-top: 50px;
}
.portfolio-carousel .item .project-details {
  padding: 0;
}
.portfolio-carousel .item .project-details img.client-logo {
  margin: 0 auto 25px;
}
.portfolio-carousel .item .project-details .project-name {
  display: block;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 5px;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}
.portfolio-carousel .item .project-details .project-description {
  display: block;
  font-size: 18px;
  line-height: 18px;
}
.portfolio-carousel .owl-controls {
  margin-top: 0;
}
.portfolio-carousel .owl-controls .owl-prev,
.portfolio-carousel .owl-controls .owl-next {
  text-align: center;
  position: absolute;
  height: 75px;
  width: 50px;
  border-radius: 0 !important;
  line-height: 70px;
  font-size: 30px !important;
  margin: auto 0 !important;
  top: 0;
  bottom: 0;
  background-color: #41c4ab !important;
  color: white !important;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.portfolio-carousel .owl-controls .owl-prev:hover,
.portfolio-carousel .owl-controls .owl-next:hover {
  opacity: 1;
}
.portfolio-carousel .owl-controls .owl-prev {
  left: 0;
}
.portfolio-carousel .owl-controls .owl-next {
  right: 0;
}
@media (min-width: 992px) {
  .portfolio-carousel .item {
    padding: 155px 55px 155px;
    text-align: left;
    min-height: 100%;
  }
  .portfolio-carousel .item .portfolio-image {
    margin-top: 0;
  }
  .portfolio-carousel .item .project-details {
    padding: 15% 0 0;
  }
  .portfolio-carousel .item .project-details img.client-logo {
    margin: 0 auto 25px 0;
  }
  .portfolio-carousel .item .project-details .project-name {
    display: block;
    font-size: 50px;
    line-height: 50px;
  }
  .portfolio-carousel .item .project-details .project-description {
    font-size: 20px;
    line-height: 20px;
  }
  .portfolio-carousel .item hr.colored {
    margin: 20px auto 20px 0;
  }
}
.portfolio-gallery .item {
  padding: 15px;
}
.portfolio-modal {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.portfolio-modal .modal-backdrop {
  display: none;
}
.portfolio-modal .modal-content {
  color: white;
  background: transparent;
  border-radius: 0;
  background-clip: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  min-height: 100%;
  padding: 100px 0;
  text-align: center;
}
.portfolio-modal .modal-content h2 {
  margin: 0;
  font-size: 46px;
}
.portfolio-modal .modal-content img {
  margin: 30px auto;
}
.portfolio-modal .modal-content .item-details {
  margin: 30px 0;
}
.portfolio-modal .close-modal {
  position: absolute;
  width: 75px;
  height: 75px;
  background-color: transparent;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}
.portfolio-modal .close-modal .lr {
  height: 75px;
  width: 1px;
  margin-left: 35px;
  background-color: white;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
  z-index: 1051;
}
.portfolio-modal .close-modal .lr .rl {
  height: 75px;
  width: 1px;
  background-color: white;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari and Chrome */
  z-index: 1052;
}
.portfolio-filter {
  display: inline-block;
}
.portfolio-filter #filters {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}
.portfolio-filter #filters li {
  display: inline-block;
}
.portfolio-filter #filters li .filter {
  display: block;
  cursor: pointer;
  padding: 10px 15px;
  margin: 2px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1px;
  font-size: 12px;
  color: #222222;
  background: transparent;
  border: 1px solid #222222;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.portfolio-filter #filters li .filter.active {
  background-color: #41c4ab;
  color: white;
  border-color: #41c4ab;
}
#portfoliolist .portfolio {
  width: 31%;
  margin: 1%;
  display: none;
  float: left;
  overflow: hidden;
}
#portfoliolist .portfolio .portfolio-wrapper {
  overflow: hidden;
  position: relative !important;
  background: #222222;
  cursor: pointer;
}
#portfoliolist .portfolio .portfolio-wrapper img {
  max-width: 100%;
  position: relative;
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
#portfoliolist .portfolio .portfolio-wrapper img:hover {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
#portfoliolist .portfolio .portfolio-wrapper .caption {
  position: absolute;
  width: 100%;
  height: 75px;
  bottom: -75px;
}
#portfoliolist .portfolio .portfolio-wrapper .caption .caption-bg {
  background: #41c4ab;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#portfoliolist .portfolio .portfolio-wrapper .caption .caption-text {
  color: white;
  position: relative;
  z-index: 500;
  padding: 15px 8px;
}
#portfoliolist .portfolio .portfolio-wrapper .caption .caption-text a.text-title {
  color: white;
}
#portfoliolist .portfolio .portfolio-wrapper .caption .caption-text .text-category {
  display: block;
  font-size: 12px;
}
/*  #Mobile (Portrait) - Note: Design for a width of 320px */
@media only screen and (max-width: 767px) {
  #portfoliolist .portfolio {
    width: 48%;
    margin: 1%;
  }
}

body.modern .portfolio-carousel .item .project-details .project-name {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.fa-fonte{
  font-size:13pt;
  color:#26b864;
}

#owl-demo{
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
}

#empresa {
  padding-top: 80px;
  padding-bottom: 80px;
}

.empresa-item {  
  padding-bottom: 40px;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.empresa-item h3 {
  font-size: 18px;
  font-weight: 600;
}
.empresa-item .service-icon {
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 113px;
  display: block;
}

#servicos {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
}

#contato{
  padding-top: 80px;
  padding-bottom: 40px;
}

#contato-aside{
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  padding: 30px;
  margin-top: 50px;
}

.input-text {
  margin-bottom: 30px;
  background-color: #f6f7f8;
  padding: 14px;
  font-size: 14px;
  color: #838383;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #b5c1c9;
  width: 100%;
}

.jssorb12{position:absolute}.jssorb12 div,.jssorb12 div:hover,.jssorb12 .av{position:absolute;width:16px;height:16px;background:url('../../../images/hevea/img/b12.png') no-repeat;overflow:hidden;cursor:pointer}.jssorb12 div{background-position:-7px -7px}.jssorb12 div:hover,.jssorb12 .av:hover{background-position:-37px -7px}.jssorb12 .av{background-position:-67px -7px}.jssorb12 .dn,.jssorb12 .dn:hover{background-position:-97px -7px}.jssora04l,.jssora04r{display:block;position:absolute;width:28px;height:40px;cursor:pointer;background:url('../../../images/hevea/img/a04.png') no-repeat;overflow:hidden}.jssora04l{background-position:-16px -39px}.jssora04r{background-position:-76px -39px}.jssora04l:hover{background-position:-136px -39px}.jssora04r:hover{background-position:-196px -39px}.jssora04l.jssora04ldn{background-position:-256px -39px}.jssora04r.jssora04rdn{background-position:-316px -39px}.jssora04l.jssora04lds{background-position:-16px -39px;opacity:.3;pointer-events:none}.jssora04r.jssora04rds{background-position:-76px -39px;opacity:.3;pointer-events:none}

